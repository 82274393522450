import { Box, Heading, Text } from "@chakra-ui/react"
import { useRating } from "lib/providers/RatingProvider"
import { Page, SectionSubHeading } from "../components"
import { OrderInfo } from "./OrderInfo"

export const CompanyInfoAndTexts: React.FC = () => {
  const { ratingState } = useRating()
  const { companyTexts } = ratingState
  const hasCompanyTexts = companyTexts?.aboutCompany || companyTexts?.companyGoals || companyTexts?.companyStrategy

  return (
    <>
      <Page>
        <OrderInfo />
        {hasCompanyTexts && (
          <Box mt="10mm">
            <SectionSubHeading mb="4mm">Ledelsens beskrivelse av {ratingState.brreg.navn}:</SectionSubHeading>
            {formatTextLines(companyTexts?.aboutCompany)}
          </Box>
        )}
      </Page>

      {hasCompanyTexts && (
        <Page>
          <SectionSubHeading mb="4mm">Vi har satt oss følgende mål for vårt bærekraftsarbeid:</SectionSubHeading>
          <Box mb="10mm">{formatTextLines(companyTexts?.companyGoals)}</Box>

          <SectionSubHeading mb="4mm">I strategien har vi følgende om bærekraft:</SectionSubHeading>
          <Box>{formatTextLines(companyTexts?.companyStrategy)}</Box>
        </Page>
      )}
    </>
  )
}

function formatTextLines(text: string) {
  const lines = text.split("\n")
  return lines.map((line, idx) => (
    <Text key={`key-${idx}`} mb="4mm">
      {line}
    </Text>
  ))
}
