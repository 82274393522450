import { Text, Textarea } from "@chakra-ui/react"
import ContentAccordion from "components/ContentAccordion"
import { ContentAccordionLeft } from "components/ContentAccordionLeft"
import { ContentAccordionRight } from "components/ContentAccordionRight"
import { FoundationHeader } from "components/FoundationHeader"
import FoundationMainContent from "components/FoundationMainContent"
import { useUpdateRatingCompanyTextMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { useForm } from "react-hook-form"

export const AboutCompany = () => {
  const { ratingId, ratingState, submissionProgress } = useRating()
  const [_saveStatus, save] = useUpdateRatingCompanyTextMutation()
  const isReadOnly =
    ratingState?.phase !== "SUBMISSION" ||
    (ratingState.reopenedScopes && !ratingState?.reopenedScopes?.includes("companyTexts"))

  const scope = "aboutCompany"

  const form = useForm({
    defaultValues: {
      text: ratingState?.companyTexts[scope],
    },
    mode: "onBlur",
  })

  const saveChanges = () => {
    if (isReadOnly) return

    save({
      ratingId,
      scope,
      text: form.getValues("text"),
    })
  }

  return (
    <FoundationMainContent>
      <FoundationHeader title="Om virksomheten" />
      <ContentAccordion
        headingText={"Om virksomheten"}
        completed={submissionProgress?.companyTexts?.aboutCompany}
        readOnly={isReadOnly}
      >
        <ContentAccordionLeft>
          <Text mb="2" fontWeight={500}>
            Skriv om virksomheten
          </Text>
          <Textarea isDisabled={isReadOnly} {...form.register("text")} h="300" bgColor="white" onBlur={saveChanges} />
        </ContentAccordionLeft>
        <ContentAccordionRight>
          <Text mb="4">
            Denne teksten plasseres nederst på siden der virksomheten deres er beskrevet. Det står følgende informasjon
            over teksten dere skriver: Navn, org.nr., adresse, næringskode, vedtektsfestet formål (fra
            Brønnøysundregistrene), målingsprofil, dato rapporten er ferdigstilt og deres kontaktperson fra denne
            målingen.
          </Text>
          <Text mb="4">
            Her kan dere inkludere ting om virksomheten deres. Eksempelvis kjernevirksomhet, produkter og/eller
            tjenester, hva dere er opptatt av, hvor dere holder til, målgruppe osv. Dette for at leserne skal få en
            bedre forståelse av hva og hvordan dere driver virksomheten deres.
          </Text>
        </ContentAccordionRight>
      </ContentAccordion>
    </FoundationMainContent>
  )
}
