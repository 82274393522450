import { Text, Textarea } from "@chakra-ui/react"
import ContentAccordion from "components/ContentAccordion"
import { ContentAccordionLeft } from "components/ContentAccordionLeft"
import { ContentAccordionRight } from "components/ContentAccordionRight"
import { FoundationHeader } from "components/FoundationHeader"
import FoundationMainContent from "components/FoundationMainContent"
import { useUpdateRatingCompanyTextMutation } from "lib/generated/graphql"
import { useRating } from "lib/providers/RatingProvider"
import { useForm } from "react-hook-form"

export const CompanyGoals = () => {
  const { ratingId, ratingState, submissionProgress } = useRating()
  const [_saveStatus, save] = useUpdateRatingCompanyTextMutation()
  const isReadOnly =
    ratingState?.phase !== "SUBMISSION" ||
    (ratingState.reopenedScopes && !ratingState?.reopenedScopes?.includes("companyTexts"))

  const scope = "companyGoals"

  const form = useForm({
    defaultValues: {
      text: ratingState?.companyTexts[scope],
    },
    mode: "onBlur",
  })

  const saveChanges = () => {
    if (isReadOnly) return

    save({
      ratingId,
      scope,
      text: form.getValues("text"),
    })
  }

  return (
    <FoundationMainContent>
      <FoundationHeader title="Bærekraftsmål" />
      <ContentAccordion
        headingText={"Bærekraftsmål"}
        completed={submissionProgress?.companyTexts?.companyGoals}
        readOnly={isReadOnly}
      >
        <ContentAccordionLeft>
          <Text mb="2" fontWeight={500}>
            Skriv inn målene deres for bærekraftsarbeidet deres
          </Text>
          <Textarea isDisabled={isReadOnly} {...form.register("text")} h="300" bgColor="white" onBlur={saveChanges} />
        </ContentAccordionLeft>
        <ContentAccordionRight>
          <Text mb="4">
            Denne teksten plasseres på ny side, etter innholdet dere har skrevet om virksomheten. I rapporten vil
            overskriften her være:Vi har satt oss følgende mål for vårt bærekraftsarbeid.
          </Text>
          <Text mb="4">
            Her kan dere inkludere hvilke mål dere har satt dere. Har dere ikke satt dere mål enda, kan dere beskrive
            hvorfor det ikke er satt og når det skal gjøres. Hvis bærekraftsrapporten fra ESG Trackr brukes til å komme
            i gang med deres bærekraftsarbeid, så kan dere skrive det.
          </Text>
        </ContentAccordionRight>
      </ContentAccordion>
    </FoundationMainContent>
  )
}
